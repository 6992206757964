var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cstab-container" }, [
    _c(
      "div",
      { staticClass: "wrappers" },
      _vm._l(_vm.cardList, function (item) {
        return _c(
          "div",
          {
            key: item.url,
            staticClass: "card",
            on: {
              click: function ($event) {
                return _vm.navPage(item.url)
              },
            },
          },
          [
            _c(
              "svg",
              { staticClass: "icon avaTag", attrs: { "aria-hidden": "true" } },
              [_c("use", { attrs: { href: "#" + item.icon1 } })]
            ),
            _c(
              "svg",
              {
                staticClass: "icon avaTag display-a",
                attrs: { "aria-hidden": "true" },
              },
              [_c("use", { attrs: { href: "#" + item.icon2 } })]
            ),
            _c("div", { staticClass: "title-icon" }, [
              _vm._v(_vm._s(item.name)),
            ]),
            _c("img", {
              staticClass: "avaTagRight",
              attrs: { src: require("./images/rightTop.png") },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }