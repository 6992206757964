<template>
    <div class="cstab-container">
        <div class = "wrappers">
            <div class="card" v-for="item in cardList" :key="item.url" @click="navPage(item.url)">
                <svg
                        class="icon avaTag"
                        aria-hidden="true"
                >
                    <use :href="`#${item.icon1}`"></use>
                </svg>

                <svg
                        class="icon avaTag display-a"
                        aria-hidden="true"
                >
                    <use :href="`#${item.icon2}`"></use>
                </svg>

                <div class="title-icon">{{item.name}}</div>
                <img src="./images/rightTop.png" class="avaTagRight">
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "CSTabCard",
        props: {cardList:Array},
        methods:{
            navPage(nav){
                this.$router.push({
                    name:nav
                })
            }
        }

    }
</script>

<style scoped>

    .card {
        width: 130px;
        height: 60px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        opacity: 1;
        border-radius: 6px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        color: black;
        margin-right: 30px;
        border: none;
    }
    .wrappers{
        display: flex;

    }
    .card:hover{
        color: #ffff;
        background-color: #00B694;
    }
    .avaTag {
        font-size: 30px;
        margin: 15px;
        margin-right: 10px;
    }
    .display-b{
        display: block;
    }
    .display-a{
        display: none;
    }
    .title-icon {
        font-size: 14px;
        opacity: 1;
    }
    .avaTagRight{
        position: absolute;
        font-size: 100px;
        right: 0px;
        top:0px;
        width: 16px;
        height: 16px;
        z-index: 1;
    }
    .card:hover .avaTag{
        display: none !important;
    }
    .card:hover .display-a{
        display: block !important;
    }
    .wrapper{

    }
</style>